import { memo, useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItems from 'menu-items';
import { menuItemsForStore, menuItemsForAdmin } from 'menu-items';
import NavGroup from './NavGroup';
import { NavItemType } from 'types';
import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import useConfig from 'hooks/useConfig';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { useNavigate } from 'react-router-dom';
import axios from '../../../utils/axios';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const navigate = useNavigate();
    const [routeState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1
    });

    const [accountInfo, setAccountInfo] = useState<any>(undefined);
    const [menuItem2, setMenuItem2] = useState<any>([]);
    const [remItems2, setRemItem2] = useState<any>([]);
    const [lastItemId, setLastItemId] = useState<string>('');

    useEffect(() => {
        const run = async () => {
            const temp = localStorage.getItem('account');
            const response = await axios.get('/api/v1/account/me');
            const account = response.data;
            setAccountInfo(account);
        };
        run();
    }, []);

    useEffect(() => {
        if (routeState.site === '') {
            navigate('/home', { replace: true });
        }
    }, [routeState]);

    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    useEffect(() => {
        let menuItem = menuItemsForStore;
        console.log(menuItem);

        if (routeState.role === 1) menuItem = menuItems;
        if (accountInfo && accountInfo.isAdmin) {
            menuItem = menuItemsForAdmin;
        }

        console.log(routeState);
        console.log(accountInfo);
        console.log(menuItem);

        let remItems: NavItemType[] = [];
        let lastItemId2: string;

        if (lastItem && lastItem < menuItem.items.length) {
            lastItemId2 = menuItem.items[lastItem - 1].id!;
            setLastItemId(lastItemId2);
            lastItemIndex = lastItem - 1;
            remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
                title: item.title,
                elements: item.children
            }));
        }

        setMenuItem2(menuItem);
        setRemItem2(remItems);
    }, [accountInfo]);

    // last menu-item to show in horizontal menu bar

    // console.log(remItems);

    // menuItem.items[0].children!.forEach((element) => {
    //     if (element.id === 'template') {
    //         element.url = '/template/?site=' + routeState.site;
    //         return;
    //     }
    // });

    if (menuItem2.length === 0) return <></>;

    let lastItemIndex = menuItem2.items.length - 1;

    const navItems = menuItem2.items.slice(0, lastItemIndex + 1).map((item: any) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems2} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    console.log(navItems.length);

    return <>{navItems}</>;
};

export default MenuList;
