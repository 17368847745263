import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Dialog,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Slide,
    SlideProps,
    Stack,
    Switch,
    Typography
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import useAuth from 'hooks/useAuth';
// import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconAppWindow, IconSettings, IconTicket, IconUser, IconCertificate2 } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import useSessionStorage from '../../../../hooks/useSessionStorage';
import axios from '../../../../utils/axios';
import EditAccountForm from '../../../../views/pages/home/EditAccountForm';
import PATForm from '../../../../views/pages/home/PATForm';
import LicenseForm from '../../../../views/pages/home/LicenseForm';
import SystemConfigForm from '../../../../views/pages/home/SystemConfigForm';
import { useIntl } from 'react-intl';

// ==============================|| PROFILE MENU ||============================== //

const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

interface ProfileSectionProps {
    openWebApp: any;
}

const ProfileSection = ({ openWebApp }: ProfileSectionProps) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const intl = useIntl();

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    const [role, setRole] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout, account } = useAuth();

    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState<{ open: boolean; mode: string }>({
        open: false,
        mode: ''
    });

    const [accountInfo, setAccountInfo] = useState<any>(undefined);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const temp = localStorage.getItem('account');
        setAccountInfo(temp === null ? {} : JSON.parse(temp));
    }, []);

    const handleModalClose = () => {
        console.log('close');
        setModal({ open: false, mode: '' });
    };
    const handleEditAccount = async () => {
        setModal({ open: true, mode: 'account' });
    };

    const handlePAT = async () => {
        setModal({ open: true, mode: 'pat' });
    };

    const handleWebApp = async () => {
        // setModal({ open: true, mode: 'app' });
        openWebApp();
    };

    const handleSystem = async () => {
        setModal({ open: true, mode: 'system' });
    };

    const handleLicense = async () => {
        setModal({ open: true, mode: 'license' });
    };

    const handleToggle = () => {
        const temp = localStorage.getItem('account');
        setAccountInfo(temp === null ? {} : JSON.parse(temp));
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const [routeState, setRouteState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1,
        store: ''
    });

    useEffect(() => {
        const init = async () => {
            if (accountInfo && accountInfo.isAdmin) {
                setRole(intl.formatMessage({ id: 'system-administrator' }));
                return;
            }

            if (routeState.service == undefined || routeState.service.length === 0) return;

            try {
                const response = await axios.get('/api/v1/' + routeState.service + '/site/' + routeState.site + '/role');
                if (response.data.role === 9999) {
                    setRole(intl.formatMessage({ id: 'system-administrator' }));
                } else if (response.data.role === -1) {
                    setRole('None');
                } else if (response.data.role === 1) {
                    setRole(intl.formatMessage({ id: 'site-administrator' }));
                } else if (response.data.role === 2) {
                    setRole(intl.formatMessage({ id: 'store-manager' }));
                } else {
                    setRole('');
                }

                if (routeState.role !== response.data.role) {
                    setRouteState({
                        ...routeState,
                        role: response.data.role
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        init();
    }, [routeState, accountInfo]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <>
                        {accountInfo && !accountInfo.picture && (
                            <Avatar
                                sx={{
                                    ...theme.typography.mediumAvatar,
                                    margin: '8px 0 8px 8px !important',
                                    cursor: 'pointer'
                                }}
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                color="inherit"
                            >
                                {accountInfo && accountInfo.name![0]}
                            </Avatar>
                        )}
                        {accountInfo && accountInfo.picture && (
                            <Avatar
                                sx={{
                                    ...theme.typography.mediumAvatar,
                                    margin: '8px 0 8px 8px !important',
                                    cursor: 'pointer'
                                }}
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                color="inherit"
                                src={accountInfo.picture}
                            ></Avatar>
                        )}
                    </>
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">
                                                        <FormattedMessage id="hi" />
                                                    </Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {accountInfo?.name}
                                                    </Typography>
                                                </Stack>
                                                {(routeState.store || '').length == 0 && (
                                                    <Typography variant="subtitle2">{role}</Typography>
                                                )}
                                            </Stack>
                                            {/*<OutlinedInput*/}
                                            {/*    sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}*/}
                                            {/*    id="input-search-profile"*/}
                                            {/*    value={value}*/}
                                            {/*    onChange={(e) => setValue(e.target.value)}*/}
                                            {/*    placeholder="Search profile options"*/}
                                            {/*    startAdornment={*/}
                                            {/*        <InputAdornment position="start">*/}
                                            {/*            <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />*/}
                                            {/*        </InputAdornment>*/}
                                            {/*    }*/}
                                            {/*    aria-describedby="search-helper-text"*/}
                                            {/*    inputProps={{*/}
                                            {/*        'aria-label': 'weight'*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            {/*<Divider />*/}
                                        </Box>
                                        {/*<PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>*/}
                                        <Box sx={{ p: 2, pt: 0, pb: 0 }}>
                                            {/*<UpgradePlanCard />*/}
                                            {/*<Divider />*/}
                                            {/*<Card*/}
                                            {/*    sx={{*/}
                                            {/*        bgcolor:*/}
                                            {/*            theme.palette.mode === 'dark'*/}
                                            {/*                ? theme.palette.dark[800]*/}
                                            {/*                : theme.palette.primary.light,*/}
                                            {/*        my: 2*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <CardContent>*/}
                                            {/*        <Grid container spacing={3} direction="column">*/}
                                            {/*            <Grid item>*/}
                                            {/*                <Grid item container alignItems="center" justifyContent="space-between">*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Typography variant="subtitle1">Start DND Mode</Typography>*/}
                                            {/*                    </Grid>*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Switch*/}
                                            {/*                            color="primary"*/}
                                            {/*                            checked={sdm}*/}
                                            {/*                            onChange={(e) => setSdm(e.target.checked)}*/}
                                            {/*                            name="sdm"*/}
                                            {/*                            size="small"*/}
                                            {/*                        />*/}
                                            {/*                    </Grid>*/}
                                            {/*                </Grid>*/}
                                            {/*            </Grid>*/}
                                            {/*            <Grid item>*/}
                                            {/*                <Grid item container alignItems="center" justifyContent="space-between">*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Typography variant="subtitle1">Allow Notifications</Typography>*/}
                                            {/*                    </Grid>*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Switch*/}
                                            {/*                            checked={notification}*/}
                                            {/*                            onChange={(e) => setNotification(e.target.checked)}*/}
                                            {/*                            name="sdm"*/}
                                            {/*                            size="small"*/}
                                            {/*                        />*/}
                                            {/*                    </Grid>*/}
                                            {/*                </Grid>*/}
                                            {/*            </Grid>*/}
                                            {/*        </Grid>*/}
                                            {/*    </CardContent>*/}
                                            {/*</Card>*/}
                                            {/*<Divider />*/}
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/*<ListItemButton*/}
                                                {/*    sx={{ borderRadius: `${borderRadius}px` }}*/}
                                                {/*    selected={selectedIndex === 0}*/}
                                                {/*    onClick={(event: React.MouseEvent<HTMLDivElement>) =>*/}
                                                {/*        handleListItemClick(event, 0, '/user/account-profile/profile1')*/}
                                                {/*    }*/}
                                                {/*>*/}
                                                {/*    <ListItemIcon>*/}
                                                {/*        <IconSettings stroke={1.5} size="1.3rem" />*/}
                                                {/*    </ListItemIcon>*/}
                                                {/*    <ListItemText*/}
                                                {/*        primary={*/}
                                                {/*            <Typography variant="body2">*/}
                                                {/*                <FormattedMessage id="account-settings" />*/}
                                                {/*            </Typography>*/}
                                                {/*        }*/}
                                                {/*    />*/}
                                                {/*</ListItemButton>*/}

                                                {(routeState.store || '').length == 0 && (
                                                    <>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            // selected={selectedIndex === 1}
                                                            // onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                            //     handleListItemClick(event, 1, '/user/social-profile/posts')
                                                            // }
                                                            onClick={handleEditAccount}
                                                        >
                                                            <ListItemIcon>
                                                                <IconUser stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Grid container spacing={1} justifyContent="space-between">
                                                                        <Grid item>
                                                                            <Typography variant="body2">
                                                                                <FormattedMessage id="account" />
                                                                            </Typography>
                                                                        </Grid>
                                                                        {/*<Grid item>*/}
                                                                        {/*    <Chip*/}
                                                                        {/*        label="02"*/}
                                                                        {/*        size="small"*/}
                                                                        {/*        sx={{*/}
                                                                        {/*            bgcolor:*/}
                                                                        {/*                theme.palette.mode === 'dark'*/}
                                                                        {/*                    ? theme.palette.dark.dark*/}
                                                                        {/*                    : theme.palette.warning.dark,*/}
                                                                        {/*            color: theme.palette.background.default*/}
                                                                        {/*        }}*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                    </Grid>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            // selected={selectedIndex === 4}
                                                            onClick={handlePAT}
                                                        >
                                                            <ListItemIcon>
                                                                <IconTicket stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        <FormattedMessage id="personal-access-token" />
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            // selected={selectedIndex === 4}
                                                            onClick={handleWebApp}
                                                        >
                                                            <ListItemIcon>
                                                                <IconAppWindow stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        <FormattedMessage id="web-app" />
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        {routeState.role === 9999 && (
                                                            <>
                                                                <Divider />
                                                                <ListItemButton
                                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                                    // selected={selectedIndex === 4}
                                                                    onClick={handleSystem}
                                                                >
                                                                    <ListItemIcon>
                                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography variant="body2">
                                                                                <FormattedMessage id="system" />
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItemButton>
                                                                <ListItemButton
                                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                                    // selected={selectedIndex === 4}
                                                                    onClick={handleLicense}
                                                                >
                                                                    <ListItemIcon>
                                                                        <IconCertificate2 stroke={1.5} size="1.3rem" />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography variant="body2">
                                                                                <FormattedMessage id="license" />
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItemButton>
                                                            </>
                                                        )}
                                                        <Divider />
                                                    </>
                                                )}
                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                <FormattedMessage id="sign-out" />
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                        {/*</PerfectScrollbar>*/}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleModalClose}
                open={modal.open}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    '&>div:nth-of-type(3)': {
                        justifyContent: 'flex-end',
                        '&>div': {
                            m: 0,
                            borderRadius: '0px',
                            maxWidth: modal.mode === 'license' ? 1200 : modal.mode === 'pat' ? 1000 : 450,
                            minWidth: modal.mode === 'license' ? 1200 : modal.mode === 'pat' ? 1000 : 450,
                            maxHeight: '100%',
                            minHeight: '100%',
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'auto',
                                minWidth: 'auto'
                            }
                        }
                    }
                }}
            >
                {modal.mode === 'account' && modal.open && <EditAccountForm onCancel={handleModalClose} />}
                {modal.mode === 'pat' && modal.open && <PATForm onCancel={handleModalClose} />}
                {modal.mode === 'system' && modal.open && <SystemConfigForm onCancel={handleModalClose} />}
                {modal.mode === 'license' && modal.open && <LicenseForm onCancel={handleModalClose} />}
                {/*{modal.mode === 'app' && modal.open && <WebAppForm onCancel={handleModalClose} />}*/}
            </Dialog>
        </>
    );
};

export default ProfileSection;
