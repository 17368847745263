// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDeviceAnalytics,
    IconBuildingStore,
    IconRouter,
    IconDevices,
    IconBox,
    IconPalette,
    IconNotes,
    IconTemplate,
    IconMapPin
} from '@tabler/icons';

import { NavItemType } from 'types';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages: NavItemType = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'store',
            title: <FormattedMessage id="store" />,
            type: 'item',
            url: '/store',
            icon: IconBuildingStore,
            breadcrumbs: false
        },
        {
            id: 'ap',
            title: <FormattedMessage id="ap" />,
            type: 'item',
            url: '/ap',
            icon: IconRouter,
            breadcrumbs: false
        },
        {
            id: 'tag',
            title: <FormattedMessage id="tag" />,
            type: 'item',
            url: '/tag',
            icon: IconDevices,
            breadcrumbs: false
        },
        // {
        //     id: 'anchor',
        //     title: <FormattedMessage id="anchor" />,
        //     type: 'item',
        //     url: '/anchor',
        //     icon: IconMapPin,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'camera',
        //     title: <FormattedMessage id="camera" />,
        //     type: 'item',
        //     url: '/camera',
        //     icon: IconCamera,
        //     breadcrumbs: false
        // },
        {
            id: 'item',
            title: <FormattedMessage id="item" />,
            type: 'item',
            url: '/item',
            icon: IconBox,
            breadcrumbs: false
        },
        {
            id: 'task',
            title: <FormattedMessage id="task" />,
            type: 'item',
            url: '/task',
            icon: IconNotes,
            breadcrumbs: false
        },
        {
            id: 'clipart',
            title: <FormattedMessage id="clipart" />,
            type: 'item',
            url: '/clipart',
            icon: IconTemplate,
            breadcrumbs: false
        },
        {
            id: 'templates',
            title: <FormattedMessage id="template-editor" />,
            type: 'item',
            url: '/templates',
            icon: IconPalette,
            breadcrumbs: false
        }
    ]
};

export const pagesForSite: NavItemType = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'clipart',
            title: <FormattedMessage id="clipart" />,
            type: 'item',
            url: '/clipart',
            icon: IconTemplate,
            breadcrumbs: false
        }
    ]
};

export const pagesForStore: NavItemType = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'store',
            title: <FormattedMessage id="store" />,
            type: 'item',
            url: '/store',
            icon: IconBuildingStore,
            breadcrumbs: false
        },

        {
            id: 'ap',
            title: <FormattedMessage id="ap" />,
            type: 'item',
            url: '/ap',
            icon: IconRouter,
            breadcrumbs: false
        },
        {
            id: 'tag',
            title: <FormattedMessage id="tag" />,
            type: 'item',
            url: '/tag',
            icon: IconDevices,
            breadcrumbs: false
        },
        {
            id: 'item',
            title: <FormattedMessage id="item" />,
            type: 'item',
            url: '/item',
            icon: IconBox,
            breadcrumbs: false
        },
        {
            id: 'task',
            title: <FormattedMessage id="task" />,
            type: 'item',
            url: '/task',
            icon: IconNotes,
            breadcrumbs: false
        },
        {
            id: 'templates',
            title: <FormattedMessage id="template-editor" />,
            type: 'item',
            url: '/templates',
            icon: IconPalette,
            breadcrumbs: false
        }
    ]
};

export default pages;
