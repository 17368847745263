// project import
import pages from './pages';
import { pagesForStore, pagesForSite } from './pages';
import configuration from './setting';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [pagesForStore, pagesForSite]
};

export const menuItemsForStore: { items: NavItemType[] } = {
    // items: [pagesForStore, extra]
    items: [pagesForStore]
};

export const menuItemsForAdmin: { items: NavItemType[] } = {
    items: [pagesForStore, pagesForSite, configuration]
};

export default menuItems;
